export const UserRole = {
  Editor: 1,
  Admin: 2,
};

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  // { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyA-2K1fpwx27A1TTMRlwLJoORP3NHsWQHQ',
  authDomain: 'ds-proveedores.firebaseapp.com',
  databaseURL: 'https://ds-proveedores.firebaseio.com/',
  projectId: 'ds-proveedores',
  storageBucket: 'ds-proveedores.appspot.com',
  messagingSenderId: '968717620406',
};

export const currentUser = {
  id: 1,
  title: 'Ricardo Rebolledo',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen allways',
  role: parseInt(localStorage.getItem('role')),
};

export const adminRoot = '/app';
export const buyUrl = 'https://digitag.cl';
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const serviceApiPath = 'https://scraping.digitag.cl/api/v1';
export const servicePath = 'https://ds.ps.digitag.cl/local/module/digitag_sellerportal/api';
export const localPath = 'http://localhost/api';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
